import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {sensorsAC} from "../store/branches/sensors/actionCreators";
import {LoadingStatus} from "../store/status";
import {ApiConfig} from "../services/config";

export const useSocketSensors = (path: string) => {
    const dispatch = useDispatch()
    const [ws, setSocket] = useState(null);

    useEffect(() => {
        const wsServerUrl = ApiConfig.getServerWssUrl();
        const token = window.localStorage.getItem("_token");
        const socket = new WebSocket(`${wsServerUrl}${path}?token=${token}`);
        const tabId = Date.now();

        localStorage.setItem('tabId', tabId.toString());

        socket.onopen = () => {
            console.warn('WS', 'open');
        }

        socket.onclose = () => {
            console.warn('WS', 'close');
        }

        socket.onerror = (error: any) => {
            console.warn("socket error ", error)
        }

        socket.onmessage = ({data}: any) => {
            dispatch(sensorsAC.setSensorsLoadingStatus(LoadingStatus.LOADING))
            dispatch(sensorsAC.fetchWsSensorsData(JSON.parse(data)))
        }

         const handleTabClose = () => {
            const activeTabId = localStorage.getItem('tabId');
            if (activeTabId === tabId.toString()) {
                socket.close();
            }
            sessionStorage.setItem('socketCloseLog', 'WebSocket closed before unload');
        };

        // Add event listener for tab close
        window.addEventListener('beforeunload', handleTabClose);

        setSocket(socket);

        return () => {
          socket.close();
          window.removeEventListener('beforeunload', handleTabClose);
        };
    }, [path, dispatch])

    return ws
}
